import React, { Component } from 'react'
import { css } from '@emotion/core'
import buildOpenCrudProvider, { buildQuery } from '@toosick/ra-data-opencrud'
import { createBrowserHistory } from 'history'
import { Admin, Resource } from 'react-admin'
import { RotateLoader } from 'react-spinners'
import { ApolloProvider } from 'react-apollo'
import { RebateList, RebateEdit, RebateShow, RebateIcon, RebateCreate } from './rebate'
import { SubscriberList, SubscriberEdit, SubscriberShow, SubscriberIcon, SubscriberCreate } from './subscriber'
import authProvider from './authProvider'
import LoginPage from './LoginPage'
import apolloClient from './services/apolloClient'
import customRoutes from './routes'
import Menu from './Menu'
import enhanceBuildQuery from '../utils/enhanceBuildQuery'

import './App.css'

export default class App extends Component {
  constructor(props) {
    super(props)
    this.state = {
      dataProvider: null,
    }
  }
  componentDidMount() {
    buildOpenCrudProvider({
      client: apolloClient,
      buildQuery: enhanceBuildQuery(buildQuery),
    }).then(dataProvider =>
      this.setState({
        dataProvider,
      }),
    )
  }
  render() {
    window.initialHash = window.location.hash
    const { dataProvider } = this.state

    if (!dataProvider) {
      return (
        <div styleName="loader-wrapper">
          <RotateLoader
            color={'#2196f3'}
            css={css`
              display: block;
              right: 0;
              bottom: 0;
              border-color: red;
            `}
          />
        </div>
      )
    }
    return (
      <ApolloProvider client={apolloClient}>
        <Admin
          dataProvider={dataProvider}
          title="Rebates"
          authProvider={authProvider}
          loginPage={LoginPage}
          history={createBrowserHistory()}
          customRoutes={customRoutes}
          menu={Menu}
        >
          <Resource
            name="Rebate"
            list={RebateList}
            edit={RebateEdit}
            show={RebateShow}
            create={RebateCreate}
            icon={RebateIcon}
          />
          <Resource
            name="Subscriber"
            list={SubscriberList}
            edit={SubscriberEdit}
            show={SubscriberShow}
            create={SubscriberCreate}
            icon={SubscriberIcon}
          />
        </Admin>
      </ApolloProvider>
    )
  }
}
