import React from 'react'
import {
  List,
  Datagrid,
  Edit,
  SimpleForm,
  TextField,
  EditButton,
  Show,
  NumberInput,
  Filter,
  TextInput,
  TabbedShowLayout,
  Tab,
  required,
  Create,
  DateField,
  ReferenceArrayInput,
  SelectArrayInput,
  ReferenceManyField,
  Pagination,
  AutocompleteInput,
  NumberField,
  DeleteButton,
  SimpleShowLayout,
  SelectInput,
  ReferenceInput,
} from 'react-admin'
import Icon from '@material-ui/icons/People'
import HiddenUserInput from './HiddenUserInput'
import nameFilterToQuery from '../utils/nameFilterToQuery'
import ResourceField from '../fields/ResourceField'
export const SubscriberIcon = Icon

const SubscriberFilter = props => (
  <Filter {...props}>
    <TextInput source="email_contains" label="Email" alwaysOn />
    <TextInput source="orderId_contains" label="Order ID" alwaysOn />
    <ReferenceInput
      label="Rebate"
      source="receivedRebate.id"
      reference="Rebate"
      filterToQuery={nameFilterToQuery}
      alwaysOn
    >
      <AutocompleteInput>
        <SelectInput />
      </AutocompleteInput>
    </ReferenceInput>
  </Filter>
)

export const SubscriberList = props => {
  return (
    <List {...props} filters={<SubscriberFilter />}>
      <Datagrid rowClick="show">
        <TextField source="email" />
        <TextField source="orderId" />
        <ResourceField source="receivedRebate.name" target="receivedRebate.id" reference="Rebate" label="Rebate" />
        <DateField source="createdAt" showTime />
        <EditButton />
      </Datagrid>
    </List>
  )
}

const SubscriberTitle = ({ record }) => {
  return <span>Subscriber {record ? `"${record.email}"` : ''}</span>
}

export const SubscriberEdit = props => (
  <Edit title={<SubscriberTitle />} {...props}>
    <SimpleForm redirect="list">
      <TextInput source="email" label="Email" validate={required()} />
      <TextInput source="orderId" label="Order ID" validate={required()} />
      <HiddenUserInput {...props} />
    </SimpleForm>
  </Edit>
)

export const SubscriberShow = props => (
  <Show title={<SubscriberTitle />} {...props}>
    <SimpleShowLayout>
      <TextField source="email" />
      <TextField source="orderId" />
      <ResourceField source="receivedRebate.name" target="receivedRebate.id" reference="Rebate" />
      <DateField source="createdAt" showTime />
    </SimpleShowLayout>
  </Show>
)

export const SubscriberCreate = props => {
  return (
    <Create title="Create Subscriber" {...props}>
      <SimpleForm redirect="list">
        <TextInput source="email" validate={required()} />
        <TextInput source="orderId" validate={required()} />
        <ReferenceInput
          label="Rebate"
          source="receivedRebate.id"
          reference="Rebate"
          filterToQuery={nameFilterToQuery}
          validate={required()}
        >
          <AutocompleteInput>
            <SelectInput />
          </AutocompleteInput>
        </ReferenceInput>
        <HiddenUserInput {...props} />
      </SimpleForm>
    </Create>
  )
}
