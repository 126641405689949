import React, { createElement } from 'react'
import { connect } from 'react-redux'
import { MenuItemLink, getResources, Responsive } from 'react-admin'
import { withRouter } from 'react-router-dom'
import SettingsIcon from '@material-ui/icons/Settings'

const Menu = ({ resources, onMenuClick, logout }) => (
  <div>
    {resources.map(resource =>
      !['None'].includes(
        resource.name,
      ) ? (
        <MenuItemLink
          key={resource.name}
          to={`/${resource.name}`}
          primaryText={
            (resource.options && resource.options.label) || resource.name
          }
          leftIcon={createElement(resource.icon)}
          onClick={onMenuClick}
        />
      ) : null,
    )}
    <MenuItemLink
      to="/settings"
      primaryText="Settings"
      leftIcon={<SettingsIcon />}
      onClick={onMenuClick}
    />
    <Responsive
      small={logout}
      medium={null} // Pass null to render nothing on larger devices
    />
  </div>
)

const mapStateToProps = state => ({
  resources: getResources(state),
})

export default withRouter(connect(mapStateToProps)(Menu))
