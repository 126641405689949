import React from 'react'
import {
  List,
  Datagrid,
  Edit,
  SimpleForm,
  TextField,
  EditButton,
  Show,
  NumberInput,
  Filter,
  TextInput,
  TabbedShowLayout,
  Tab,
  required,
  Create,
  DateField,
  ReferenceArrayInput,
  SelectArrayInput,
  ReferenceManyField,
  Pagination,
  AutocompleteInput,
  NumberField,
  DeleteButton,
  SimpleShowLayout,
} from 'react-admin'
import Icon from '@material-ui/icons/MoneyOff'
import HiddenUserInput from './HiddenUserInput'
export const RebateIcon = Icon

const RebateFilter = props => (
  <Filter {...props}>
    <TextInput source="name_contains" label="Name" alwaysOn />
  </Filter>
)

export const RebateList = props => {
  return (
    <List {...props} filters={<RebateFilter />}>
      <Datagrid rowClick="show">
        <TextField source="name" />
        <TextField source="dailyAmount" />
        <TextField source="leftAmount" label="Today Rebates Left" />
        <EditButton />
      </Datagrid>
    </List>
  )
}

const RebateTitle = ({ record }) => {
  return <span>Rebate {record ? `"${record.name}"` : ''}</span>
}

export const RebateEdit = props => (
  <Edit title={<RebateTitle />} {...props}>
    <SimpleForm redirect="list">
      <TextInput source="name" validate={required()} />
      <NumberInput source="dailyAmount" validate={required()} />
      <NumberInput source="leftAmount" validate={required()} />
      <TextInput source="keyword1" label="Keyword 1 Link" validate={required()} />
      <TextInput source="keyword2" label="Keyword 2 Link" />
      <TextInput source="keyword3" label="Keyword 3 Link" />
      <TextInput source="keyword4" label="Keyword 4 Link" />
      <TextInput source="keyword5" label="Keyword 5 Link" />
      <HiddenUserInput {...props} />
    </SimpleForm>
  </Edit>
)

export const RebateShow = props => (
  <Show title={<RebateTitle />} {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="name" />
      <NumberField source="dailyAmount" />
      <NumberField source="leftAmount" />
      <DateField source="createdAt" showTime />
      <DateField source="lastResetDate" showTime />
      <TextField source="keyword1" label="Keyword 1 Link" />
      <TextField source="keyword2" label="Keyword 2 Link" />
      <TextField source="keyword3" label="Keyword 3 Link" />
      <TextField source="keyword4" label="Keyword 4 Link" />
      <TextField source="keyword5" label="Keyword 5 Link" />
    </SimpleShowLayout>
  </Show>
)

export const RebateCreate = props => {
  return (
    <Create title="Create Rebate" {...props}>
      <SimpleForm redirect="list">
        <TextInput source="name" validate={required()} />
        <NumberInput source="dailyAmount" validate={required()} />
        <TextInput source="keyword1" label="Keyword 1 Link" validate={required()} />
        <TextInput source="keyword2" label="Keyword 2 Link" />
        <TextInput source="keyword3" label="Keyword 3 Link" />
        <TextInput source="keyword4" label="Keyword 4 Link" />
        <TextInput source="keyword5" label="Keyword 5 Link" />
        <HiddenUserInput {...props} />
      </SimpleForm>
    </Create>
  )
}
